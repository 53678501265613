import logo from './logo.svg';
import './App.css';
import React, { useRef, useState, useEffect } from 'react';

function App() {

  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const projectsRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToRef = (ref) => {
    if (ref.current) {

      const offsetTop = ref.current.offsetTop;
      

      const headerHeightInPx = 5 * 16;
      
      const position = offsetTop - headerHeightInPx;
      
      window.scrollTo({
        top: position,
        behavior: 'smooth'
      });
    }
  };

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1024) { // 1024px is the "lg" breakpoint in Tailwind CSS
        homeRef.current.style.height = 'calc(100vh - 5rem)';
      } else {
        homeRef.current.style.height = ''; // Resetting the height if not "lg" or above
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className="App">

      

      <nav className='flex flex-row justify-between items-center sticky top-0 z-10 h-20 px-3 sm:px-10 font-poppins bg-white drop-shadow-sm'>
            <div className='text-2xl font-bold text-header-color cursor-pointer'>Alex</div>

            <div className='lg:hidden'>
              <button onClick={toggleMenu} className="focus:outline-none hover:text-blue-500 transition-colors duration-250 ease-in">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
            </div>

            <div className={`lg:flex ${showMenu && false ? 'bFlock' : 'hidden'}`}>
              <div className='flex space-x-5 text-custom font-semibold text-header-color'>
                <button onClick={() => scrollToRef(homeRef)} className="cursor-pointer hover:text-blue-500 transition-colors duration-250 ease-in">Home</button>
                <button onClick={() => scrollToRef(aboutRef)} className="cursor-pointer hover:text-blue-500 transition-colors duration-250 ease-in">About</button>
                <button onClick={() => scrollToRef(projectsRef)} className="cursor-pointer hover:text-blue-500 transition-colors duration-250 ease-in">Projects</button>
                <button onClick={() => scrollToRef(contactRef)} className="cursor-pointer hover:text-blue-500 transition-colors duration-250 ease-in">Contact</button>
              </div>
            </div>
          </nav>

          <div className={`fixed inset-0 flex items-center justify-center bg-white z-20 transform transition-transform duration-500 ease-in-out ${showMenu ? 'translate-x-0' : '-translate-x-full'}`}>
            <div className="absolute top-0 right-0 mt-5 mr-2 sm:mt-5 sm:mr-9">
              <button onClick={toggleMenu} className="text-header-color hover:text-blue-500 transition-colors duration-250 ease-in focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
              </button>
            </div>

            <div className={`bg-white p-4 rounded-md `}>
                  <button onClick={() => { scrollToRef(homeRef); toggleMenu(); }} className="block w-full mt-8 text-center text-header-color hover:text-blue-500 transition-colors duration-250 ease-in text-2xl font-medium font-poppins">Home</button>
                  <button onClick={() => { scrollToRef(aboutRef); toggleMenu(); }} className="block w-full mt-8 text-center text-header-color hover:text-blue-500 transition-colors duration-250 ease-in text-2xl font-medium font-poppins">About</button>
                  <button onClick={() => { scrollToRef(projectsRef); toggleMenu(); }} className="block w-full mt-8 text-center text-header-color hover:text-blue-500 transition-colors duration-250 ease-in text-2xl font-medium font-poppins">Portfolio</button>
                  <button onClick={() => { scrollToRef(contactRef); toggleMenu(); }} className="block w-full mt-8 text-center text-header-color hover:text-blue-500 transition-colors duration-250 ease-in text-2xl font-medium font-poppins">Contact</button>
            </div>
          </div>


          <section ref={homeRef} className="bg-background-color h-auto lg:h-screen">

            <div className="flex flex-col lg:flex-row justify-center items-center lg:items-stretch lg:h-full">

                  <div className='w-full lg:w-[600px] flex flex-col justify-center space-y-6 mt-14 lg:mt-0'>
                  <div className="text-center lg:text-left relative inline-block">
                      <h1 className="text-customHeaderFont sm:text-5xl lg:text-6xl font-poppins font-bold text-header-color inline-block">Full-Stack React <br /> Developer <span className="absolute text-5xl mt-1 ml-2">👋🏼</span></h1>
                        
                    </div>
                      <p class="text-lg font-mulish text-subheader-color text-center lg:text-left m-1 xs:m-0">
                        Hi, I'm Alex Parsons. A passionate Full-Stack React
                        <br class="hidden xs:inline" />
                        <span class="inline xs:hidden">&nbsp;</span>
                        <span class="xs:hidden">developer</span><span class="hidden sm:inline">Developer</span> based in San Diego, CA 📍
                      </p>
                      <div className="flex space-x-4 justify-center lg:justify-start">
                        <button className='cursor-pointer hover:text-blue-500 transition-colors duration-175 ease-in'>
                          <a href="https://www.linkedin.com/in/ahparsons/" target="_blank" rel="noopener noreferrer" className='cursor-pointer hover:text-blue-500 transition-colors duration-175 ease-in'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="tabler-icon tabler-icon-brand-linkedin">
                              <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                              <path d="M8 11v5"></path>
                              <path d="M8 8v.01"></path>
                              <path d="M12 16v-5"></path>
                              <path d="M16 16v-3a2 2 0 0 0 -4 0"></path>
                            </svg>
                          </a>
                        </button>
                        <button className='cursor-pointer hover:text-blue-500 transition-colors duration-175 ease-in'>
                          <a href="https://github.com/alexwastaken" target="_blank" rel="noopener noreferrer" className='cursor-pointer hover:text-blue-500 transition-colors duration-175 ease-in'>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="tabler-icon tabler-icon-brand-github">
                                    <path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5"></path>
                                  </svg>
                                </a>
                        </button>
                      </div>

                      <div className="flex flex-col lg:flex-row items-center">
                        <p className="font-poppins text-header-color text-xl mt-12 lg:mt-28 border-b-2 p-1 border-gray-700 border-opacity-50 lg:border-b-0 lg:border-r-2 lg:pr-4">Skill Stack</p>
                        
                        <div className="flex flex-col lg:flex-row justify-center lg:justify-start mb-10 lg:mb-0 mt-8 lg:mt-28 lg:ml-4 gap-y-6">

                          <div class="flex flex-row justify-center hover:-translate-y-2 transition-transform duration-300">
                            <img className="pl-2 cursor-pointer" src="https://skillicons.dev/icons?i=react" alt="React" />
                            <img className="pl-2 cursor-pointer" src="https://skillicons.dev/icons?i=aws" alt="AWS" />

                            <img className="pl-2 cursor-pointer" src="https://skillicons.dev/icons?i=python" alt="Tailwind" />
                            <img className="pl-2 cursor-pointer" src="https://skillicons.dev/icons?i=javascript" alt="Figma" />
                          </div>

                          <div class="flex flex-row  justify-center hover:-translate-y-2 transition-transform duration-300">
                            <img className="pl-2 cursor-pointer" src="https://skillicons.dev/icons?i=tailwind" alt="JavaScript" />
                            <img className="pl-2 cursor-pointer" src="https://skillicons.dev/icons?i=figma" alt="Python" />

                            <img className="pl-2 cursor-pointer" src="https://skillicons.dev/icons?i=html" alt="HTML" />
                            <img className="pl-2 cursor-pointer" src="https://skillicons.dev/icons?i=css" alt="CSS" />
                          </div>

                        </div>
                    </div>
                  </div>

                  <div className='flex flex-col justify-center items-center order-first lg:order-none lg:ml-24 space-y-6 mt-14 lg:-mt-52'>
                    <img src="/profile.png" className='rounded-full lg:2ml-1 w-[325px] h-[325px] sm:w-[350px] sm:h-[350px]' alt="Profile Picture"></img>
                  </div>

              </div>

            </section>

            <section ref={aboutRef} className="flex flex-col lg:flex-row justify-center items-center bg-white px-4 lg:px-8">
                <div className='my-24 lg:my-36'>
                    <img src="/myinterest.png" className='w-[350px] h-[350px] mt-0 lg:mt-0 rounded-md' alt="Example"></img>
                </div>
                <div className='lg:pl-32 text-center mb-24 max-w-md lg:max-w-2xl lg:mb-0 lg:text-left font-poppins text-header-color'>
                    <h1 className='text-xl font-bold text-blue-500'>About Me</h1>
                    <h1 className='text-2xl font-bold mt-3'>Full Stack Web Development <br /> Based in San Diego, CA 📍</h1>
                    <p className='text-bold mt-4 text-subtext-color leading-6'>Hey, I'm Alex a full time employee at Sony Electronics writing software for our logistics team.<br /> <br />
                   I enjoy creating algorithms that solve complex problems and giving end users a visually appealing way to interact with solutions.</p>
                </div>
            </section>

      <section ref={projectsRef} className="flex flex-col items-center bg-background-color font-poppins">
        <div className='text-center lg:text-left mt-24 font-poppins px-4 sm:px-6 lg:px-0 sm:mx-24 lg:mx-auto'>
          <h1 className='text-xl font-bold text-blue-500'>Portfolio</h1>
          <div className='text-2xl font-bold mt-3 text-header-color'>
            <span>Each endeavor further enhanced 
            my knowledge of engineering and design 🚀</span>
          </div>
        </div>


        <div className='flex flex-col lg:flex-row mt-20 lg:mt-28'>
          <img src="/vifiai.png" className='w-[380px] h-[270px] sm:w-[480px] sm:h-[350px] rounded-md' alt="Example"></img>
          <div className='flex flex-col items-center mt-14 lg:mt-0 lg:ml-24'>
            <h1 className='text-xl font-bold text-header-color'>Voyager AI - Sony Electronics</h1>
            <h1 className='text-lg mt-6 w-[350px] text-subheader-color'>This work assignment delivered a sophisticated tool for Sony Electronics, automating and optimizing TV placement in semi-truck trailers akin to Tetris. The bulk of the effort was concentrated on the backend algorithm, complemented by a user-friendly front-end interface and the integration of ChatGPT's API, enhancing system interactivity and efficiency.</h1>
            <div className="flex flex-row mt-4 text-md space-x-4 font-bold text-header-color">
              <h1>Python/REACT</h1>
              <h1>AWS</h1>
              </div>
              <div className="flex flex-row mt-8 text-md space-x-14 text-lg font-bold text-header-color">
                
              <a href="https://github.com/alexwastaken/voyageralgorithm/blob/main/algorithm.py" class="flex items-center hover:text-blue-500 transition-colors duration-250 ease-in hover:cursor-pointer">
                <h1 class="text-lg mr-2">Code</h1>
                <svg class="w-7 h-7 fill-current" viewBox="0 0 1024 1024">
                  <path d="M511.6 76.3C264.3 76.2 64 276.4 64 523.5 64 718.9 189.3 885 363.8 946c23.5 5.9 19.9-10.8 19.9-22.2v-77.5c-135.7 15.9-141.2-73.9-150.3-88.9C215 726 171.5 718 184.5 703c30.9-15.9 62.4 4 98.9 57.9 26.4 39.1 77.9 32.5 104 26 5.7-23.5 17.9-44.5 34.7-60.8-140.6-25.2-199.2-111-199.2-213 0-49.5 16.3-95 48.3-131.7-20.4-60.5 1.9-112.3 4.9-120 58.1-5.2 118.5 41.6 123.2 45.3 33-8.9 70.7-13.6 112.9-13.6 42.4 0 80.2 4.9 113.5 13.9 11.3-8.6 67.3-48.8 121.3-43.9 2.9 7.7 24.7 58.3 5.5 118 32.4 36.8 48.9 82.7 48.9 132.3 0 102.2-59 188.1-200 212.9a127.5 127.5 0 0138.1 91v112.5c.8 9 0 17.9 15 17.9 177.1-59.7 304.6-227 304.6-424.1 0-247.2-200.4-447.3-447.5-447.3z"></path>
                </svg>
              </a>

              <a href="https://www.youtube.com/watch?v=3XD7BQTdBtw" class="flex items-center hover:text-blue-500 transition-colors duration-250 ease-in hover:cursor-pointer">
                <div class="flex items-center hover:text-blue-500 transition-colors duration-250 ease-in hover:cursor-pointer">
                    <h1 class="text-lg mr-2">Live Demo</h1>
                    <svg viewBox="0 0 512 512" fill="currentColor" height="1em" width="1em" class="w-6 h-6" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={32} d="M384 224v184a40 40 0 01-40 40H104a40 40 0 01-40-40V168a40 40 0 0140-40h167.48M336 64h112v112M224 288L440 72" />
                    </svg>
                </div>
              </a>

              </div>
          </div>
        </div>


        <div className='flex flex-col lg:flex-row  mt-20 sm:mt-32'>
          <div className='flex flex-col items-center mt-14 lg:mt-0 lg:mr-24'>
            <h1 className='text-xl font-bold text-header-color'>Lotto Club</h1>
            <h1 className='text-lg mt-6 w-[350px] text-subheader-color'>This project was my introduction to React, where I aimed to build a website consolidating key lottery data into just a couple of pages for easy reference. Unfortunately, the API I used has since become deprecated. Below you'll find the code I wrote for this project a year ago.</h1>
            <div className="flex flex-row mt-4 text-md space-x-4 font-bold text-header-color">
              <h1>REACT</h1>
              <h1>AWS Amplify</h1>
              </div>
              <div className="flex flex-row mt-8 text-md space-x-14 text-lg font-bold text-header-color">
                
              <a href="https://github.com/alexwastaken/lottery/blob/main/src/components/Powerball.js" class="flex items-center hover:text-blue-500 transition-colors duration-250 ease-in hover:cursor-pointer">
                <h1 class="text-lg mr-2">Code</h1>
                <svg class="w-7 h-7 fill-current" viewBox="0 0 1024 1024">
                  <path d="M511.6 76.3C264.3 76.2 64 276.4 64 523.5 64 718.9 189.3 885 363.8 946c23.5 5.9 19.9-10.8 19.9-22.2v-77.5c-135.7 15.9-141.2-73.9-150.3-88.9C215 726 171.5 718 184.5 703c30.9-15.9 62.4 4 98.9 57.9 26.4 39.1 77.9 32.5 104 26 5.7-23.5 17.9-44.5 34.7-60.8-140.6-25.2-199.2-111-199.2-213 0-49.5 16.3-95 48.3-131.7-20.4-60.5 1.9-112.3 4.9-120 58.1-5.2 118.5 41.6 123.2 45.3 33-8.9 70.7-13.6 112.9-13.6 42.4 0 80.2 4.9 113.5 13.9 11.3-8.6 67.3-48.8 121.3-43.9 2.9 7.7 24.7 58.3 5.5 118 32.4 36.8 48.9 82.7 48.9 132.3 0 102.2-59 188.1-200 212.9a127.5 127.5 0 0138.1 91v112.5c.8 9 0 17.9 15 17.9 177.1-59.7 304.6-227 304.6-424.1 0-247.2-200.4-447.3-447.5-447.3z"></path>
                </svg>
              </a>

              </div>
          </div>

          <img src="/lottoclub.gif" className='w-[350px] h-[270px] sm:w-[460px] sm:h-[350px] rounded-md order-first lg:order-last' alt="Example"></img>
        
        </div>


        <div className='flex flex-col lg:flex-row mt-20 mb-20 sm:mt-32'>
          <img src="/figma.png" className='w-[400px] h-[270px] sm:w-[480px] sm:h-[340px] rounded-md' alt="Example"></img>
          <div className='flex flex-col items-center mt-14 lg:mt-0 lg:ml-24'>
            <h1 className='text-xl font-bold text-header-color'>Figma Design Projects</h1>
            <h1 className='text-lg mt-6 w-[350px] text-subheader-color'>Over two months, I dedicated myself to mastering Figma for designing aesthetically pleasing websites, followed by recreating these designs with HTML and CSS. I delved into creating animations, parallax effects, and other techniques to add interactivity to my web designs.</h1>
            <div className="flex flex-row mt-4 text-md space-x-4 font-bold text-header-color">
              <h1>Figma</h1>
              <h1>Paralax</h1>
              </div>
              <div className="flex flex-row mt-8 text-md space-x-14 text-lg font-bold text-header-color">
                
              <a href="https://www.figma.com/file/9PREUkjyJTacLMymtNfvXE/Untitled?type=design&node-id=0%3A1&mode=design&t=Q122nYtqtzddsu8W-1" target="_blank" class="flex items-center no-underline text-current hover:text-blue-500 transition-colors duration-250 ease-in hover:cursor-pointer">
                  <h1 class="text-lg mr-2">Figma Link</h1>
                  <svg viewBox="0 0 512 512" fill="currentColor" class="w-6 h-6" xmlns="http://www.w3.org/2000/svg">
                      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M384 224v184a40 40 0 01-40 40H104a40 40 0 01-40-40V168a40 40 0 0140-40h167.48M336 64h112v112M224 288L440 72" />
                  </svg>
              </a>

              </div>
          </div>
        </div>

        
      </section>


      <section ref={contactRef} className="flex flex-col items-center font-poppins bg-white">
      
        <div className='text-center lg:text-left lg:w-[998px] mt-24 mb-24 font-poppins'>
          <h1 className='text-xl font-bold text-blue-500'>Contact Me</h1>
          <h1 className='text-2xl font-bold mt-3 text-header-color'>Feel free to get in touch! 👇🏻</h1>
          
          <div className="flex flex-col lg:flex-row items-center mt-10">
            <div className="flex items-center justify-center bg-white rounded-full shadow-md text-blue-500 h-16 w-16">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">

                  <path d="M11 18l-2-1-6 3V7l6-3 6 3 6-3v7.5"/>

                  <path d="M9 4v13"/>

                  <path d="M15 7v5"/>

                  <circle cx="18" cy="18" r="3"/>

                  <line x1="20.2" y1="20.2" x2="22" y2="22"/>
                </svg>
                
              </div>
              <div className="flex flex-col mt-4 lg:mt-0">
                  <div className="lg:ml-5 text-md font-bold">Location</div>
                   <div className="lg:ml-5 text-md text-subheader-color cursor-pointer hover:text-blue-500 transition-colors duration-175 ease-in">San Diego, CA</div>
              </div>



              <div className="flex items-center justify-center bg-white rounded-full shadow-md text-blue-500 h-16 w-16 mt-6 lg:mt-0 lg:ml-32">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="tabler-icon tabler-icon-mail">
               
                <rect x="3" y="5" width="18" height="14" rx="2" ry="2"/>
             
                <polyline points="3 7 12 13 21 7"/>
              </svg>
                              
              </div>

              <div className="flex flex-col mt-4 lg:mt-0">

                  <div className="lg:ml-5 text-md font-bold">Email</div>
                  <a href="mailto:alexhparsons@gmail.com" class="no-underline text-current">
                    <div className="lg:ml-5 text-md text-subheader-color hover:text-blue-500 transition-colors duration-175 ease-in">alexhparsons@gmail.com</div>
                  </a>
              </div>

              
            </div>

        </div>
      </section>

      <section className="flex flex-col items-center font-poppins bg-background-color">
        <div className='flex flex-col lg:flex-row lg:justify-between text-center lg:text-left lg:w-[998px] mt-16 mb-16 font-poppins'>
          <h1 className='text-xl font-bold mt-3 text-header-color'>Copyright © 2024. All rights are reserved</h1>
          <div class="flex space-x-4 justify-center mt-6 lg:mt-0 ">
                <a href="https://www.linkedin.com/in/ahparsons/" target="_blank" rel="noopener noreferrer" className='cursor-pointer hover:text-blue-500 transition-colors duration-175 ease-in'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="tabler-icon tabler-icon-brand-linkedin"><path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path><path d="M8 11v5"></path><path d="M8 8v.01"></path><path d="M12 16v-5"></path><path d="M16 16v-3a2 2 0 0 0 -4 0"></path></svg>
                </a>
                <a href="https://github.com/alexwastaken" target="_blank" rel="noopener noreferrer" className='cursor-pointer hover:text-blue-500 transition-colors duration-175 ease-in'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="tabler-icon tabler-icon-brand-github hover:text-blue-500 transition-colors duration-250 ease-in hover:cursor-pointer"><path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5"></path></svg>
                </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;